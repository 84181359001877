import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Link,
  InputAdornment,
  IconButton,
  Alert,
  SelectChangeEvent,
  Paper,
  InputLabel,
  useTheme,
  Stack,
  Fade,
  Stepper,
  Step,
  StepLabel,
  Grid,
  CircularProgress,
} from '@mui/material';
import { Visibility, VisibilityOff, ArrowForward, ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { authAPI } from '../apis';
import { User } from '../types';
import { styled } from '@mui/material/styles';
import loginBg from '../assets/images/login-background.png';

type SignUpForm = Omit<User, 'userID'> & {
  password: string;
  confirmPassword: string;
};

const USER_TYPES = ['developer', 'government'];
const LANGUAGES = ['English', 'Spanish', 'French', 'Chinese', 'Arabic'];

const STEPS = ['Basic Information', 'Additional Details'];

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: 400,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#F9F9F9',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.light,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
    },
  },
  '& .MuiInputBase-input': {
    padding: '16px 24px', 
    fontSize: '16px',
    fontWeight: 400,
  },
}));

const SignUp: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<SignUpForm>({
    userType: '',
    email: '',
    phone: '',
    username: '',
    organization: '',
    password: '',
    confirmPassword: '',
    preferredLanguage: 'English',
    notificationPreferences: ['email', 'sms'],
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [marketingAccepted, setMarketingAccepted] = useState(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  useEffect(() => {
    if (formData.confirmPassword && formData.password !== formData.confirmPassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError(null);
    }
  }, [formData.password, formData.confirmPassword]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validateStep = (step: number) => {
    if (step === 0) {
      if (!formData.email || !formData.password || !formData.confirmPassword || !formData.username) {
        setError('Please fill in all required fields for this step');
        return false;
      }
      if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match');
        return false;
      }
    } else if (step === 1) {
      if (!formData.userType || !formData.phone || !formData.organization) {
        setError('Please fill in all required fields for this step');
        return false;
      }
      if (!termsAccepted) {
        setError('Please accept the Terms of Use and Privacy Policy');
        return false;
      }
    }
    setError(null);
    return true;
  };

  const handleNext = () => {
    if (validateStep(activeStep)) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setError(null);
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateStep(activeStep)) {
      return;
    }

    try {
      setLoading(true);
      setError(null);
      
      const response = await authAPI.signup({
        userType: formData.userType,
        email: formData.email,
        phone: formData.phone,
        username: formData.username,
        organization: formData.organization,
        password: formData.password,
        preferredLanguage: formData.preferredLanguage,
        notificationPreferences: ['email', 'sms'],
        marketingConsent: marketingAccepted,
      });

      if (response.status === 'success') {
        navigate('/login');
      } else {
        throw new Error(response.message || 'Failed to create account');
      }
    } catch (error) {
      console.error('Signup error:', error);
      const message = (error instanceof Error) ? error.message : 'An unknown error occurred.';
      setError(message.includes('already exists') ? message : 'Failed to create account. Please check details and try again.');
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Stack spacing={2}>
            <StyledTextField
              fullWidth
              required
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              placeholder="Choose a username"
            />
            <StyledTextField
              fullWidth
              required
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter your email address"
            />
            <StyledTextField
              fullWidth
              required
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Enter your password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <StyledTextField
              fullWidth
              required
              label="Confirm Password"
              name="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              value={formData.confirmPassword}
              onChange={handleInputChange}
              placeholder="Re-enter your password"
              error={!!passwordError}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        );
      case 1:
        return (
          <Stack spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="user-type-label">Role</InputLabel>
              <Select
                labelId="user-type-label"
                value={formData.userType}
                onChange={handleSelectChange}
                name="userType"
                label="Role"
                sx={{
                  borderRadius: '12px',
                  backgroundColor: '#F9F9F9',
                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                  '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.light },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.main, borderWidth: '1px' },
                }}
              >
                <MenuItem value="" disabled><em>Select your role</em></MenuItem>
                {USER_TYPES.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <StyledTextField
              fullWidth
              required
              label="Phone Number"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Enter your phone number"
            />
            <StyledTextField
              fullWidth
              required
              label="Organization"
              name="organization"
              value={formData.organization}
              onChange={handleInputChange}
              placeholder="Enter your organization name"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  required
                  sx={{ p: 0.5, mr: 0.5 }}
                />
              }
              label={
                <Typography variant="body2" sx={{ fontSize: '14px', color: 'text.secondary' }}>
                  I agree to the{' '}
                  <Link href="#" target="_blank" sx={{ color: 'primary.main', textDecoration: 'none' }}>
                    Terms of Use
                  </Link> &{' '}
                  <Link href="#" target="_blank" sx={{ color: 'primary.main', textDecoration: 'none' }}>
                    Privacy Policy
                  </Link>.
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={marketingAccepted}
                  onChange={(e) => setMarketingAccepted(e.target.checked)}
                  sx={{ p: 0.5, mr: 0.5 }}
                />
              }
              label={
                <Typography variant="body2" sx={{ fontSize: '14px', color: 'text.secondary' }}>
                  Receive updates and marketing emails.
                </Typography>
              }
            />
          </Stack>
        );
      default:
        return null;
    }
  };

  const isStepValid = useMemo(() => {
    if (activeStep === 0) {
      return (
        formData.email.trim() !== '' &&
        formData.username.trim() !== '' &&
        formData.password.trim() !== '' &&
        formData.confirmPassword.trim() !== '' &&
        !passwordError
      );
    } else if (activeStep === 1) {
      return (
        formData.userType.trim() !== '' &&
        formData.phone.trim() !== '' &&
        formData.organization.trim() !== '' &&
        termsAccepted
      );
    }
    return false;
  }, [
    activeStep,
    formData.email,
    formData.username,
    formData.password,
    formData.confirmPassword,
    formData.userType,
    formData.phone,
    formData.organization,
    termsAccepted,
    passwordError
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        bgcolor: '#F0F2F5',
        p: { xs: 2, sm: 3, md: 4 },
      }}
    >
      <Paper
        elevation={8}
        sx={{
          display: 'flex',
          borderRadius: '16px',
          overflow: 'hidden',
          maxWidth: '960px',
          width: '100%',
          boxShadow: '0 8px 40px rgba(0,0,0,0.12)'
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={7} md={6}>
            <Box
              sx={{
                py: { xs: 3, sm: 4, md: 5 },
                px: { xs: 3, sm: 4, md: 5 },
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
              }}
            >
              <LogoContainer>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4169E1' }}>
                  Augence
                </Typography>
              </LogoContainer>
              
              <Typography component="h1" variant="h5" sx={{ fontWeight: 700, mb: 1, color: '#313850', textAlign: 'center' }}>
                Create Account
              </Typography>

              <Stepper activeStep={activeStep} sx={{ width: '100%', mb: 3 }}>
                {STEPS.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {error && (
                <Alert severity="error" sx={{ mb: 2, width: '100%', borderRadius: '8px' }}>
                  {error}
                </Alert>
              )}

              <Box
                component="form"
                noValidate
                onSubmit={activeStep === STEPS.length - 1 ? handleSubmit : (e) => { e.preventDefault(); handleNext(); }}
                sx={{ mt: 1, width: '100%' }}
              >
                <Fade in timeout={500} key={activeStep}> 
                  <Box sx={{ 
                      minHeight: 350, 
                      maxWidth: 400, 
                      margin: '0 auto' 
                  }}>
                    {renderStepContent(activeStep)}
                  </Box>
                </Fade>

                <Stack 
                  direction="row" 
                  spacing={2} 
                  sx={{
                    mt: 2, 
                    maxWidth: 400, 
                    margin: '0 auto' 
                  }}
                >
                  {activeStep > 0 && (
                    <Button
                      variant="outlined"
                      onClick={handleBack}
                      startIcon={<ArrowBack />}
                      sx={{
                        flex: 1,
                        py: 1.5,
                        borderRadius: '200px',
                        textTransform: 'none',
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading || !isStepValid}
                    endIcon={activeStep === STEPS.length - 1 ? undefined : <ArrowForward />}
                    sx={{
                      flex: activeStep > 0 ? 1 : 'auto',
                      width: activeStep === 0 ? '100%' : undefined,
                      py: 1.5,
                      borderRadius: '200px',
                      textTransform: 'none',
                      fontSize: '16px',
                      fontWeight: 600,
                      bgcolor: '#4169E1',
                      boxShadow: '0 4px 12px rgba(65, 105, 225, 0.3)',
                      '&:hover': {
                        bgcolor: '#3457CD',
                      },
                      opacity: (!isStepValid || loading) ? 0.7 : 1,
                    }}
                  >
                    {loading && activeStep === STEPS.length - 1 
                      ? <CircularProgress size={24} color="inherit" /> 
                      : activeStep === STEPS.length - 1 
                      ? 'Create Account' 
                      : 'Next'}
                  </Button>
                </Stack>
              </Box>

              <Grid container justifyContent="center" sx={{ mt: 3 }}>
                <Grid item>
                  <Link 
                    href="#"
                    onClick={(e) => { e.preventDefault(); navigate('/login'); }}
                    variant="body2"
                    sx={{ textDecoration: 'none', color: 'text.secondary' }}
                  >
                    {"Already have an account? "}
                    <Typography component="span" sx={{ fontWeight: 600, color: 'primary.main' }}>
                      Log In
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid
            item
            xs={false} 
            sm={5}
            md={6}
            sx={{
              backgroundImage: `url(${loginBg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderTopRightRadius: '16px',
              borderBottomRightRadius: '16px',
            }}
          />
        </Grid>
      </Paper>
    </Box>
  );
};

export default SignUp; 